<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <strong>Analisis Kredit</strong>
              <!-- <a-button
                class="btn btn-outline-primary btn-sm"
                id="buttontambahdiindex"
                @click="sendToForm('create')"
                >Tambah</a-button
              > -->
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <a-month-picker :default-value="moment()" format="MMMM YYYY" class="float-right" @change="selectDate" />
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <a-table
                :bordered="true"
                :columns="columns"
                :data-source="datatable"
                size="small"
                style="
                  margin-left: -26px;
                  margin-right: -26px;
                  margin-top: -21px;
                "
                :customRow="customRow"
                :loading="loading"
                :pagination="{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total) => `Total ${total} items`,
                  pageSizeOptions: ['10', '20', '30'],
                }"
              >
                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px;"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`Search ${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block;"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px;"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  >
                    Search
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px;"
                    @click="() => handleReset(clearFilters)"
                  >
                    Reset
                  </a-button>
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template
                  slot="customRender"
                  slot-scope="text, record, index, column"
                >
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    <a-dropdown :trigger="['contextmenu']">
                      <span v-if="column.dataIndex === 'id_pemohon'">
                        {{ record.pemohon === null ? 'Tidak ada pemohon' : record.pemohon.nama }}
                      </span>
                      <span v-else> {{ text }}</span>
                      <a-menu slot="overlay">
                        <a-menu-item>
                          <a-icon
                            type="edit"
                            class="text-warning"
                            @click="sendToForm('update', record)"
                          />
                          <span class="ml-1 text-warning">Edit</span>
                        </a-menu-item>
                        <a-menu-item>
                          <a-icon
                            type="delete"
                            class="text-danger"
                            @click="showDeleteConfirm(record)"
                          />
                          <span class="ml-1 text-danger">Hapus</span>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </template>
                </template>
                <template slot="operations" slot-scope="text, record">
                  <a-icon type="edit" class="text-warning"
                    @click="sendToForm('update',record)"/>
                  <a-divider type="vertical" />
                  <a-icon
                    type="delete"
                    class="text-danger"
                    @click="showDeleteConfirm(record)"
                  />
                  <a-divider type="vertical" />
                  <a-icon
                    type="printer"
                    class="text-success"
                    @click="getExcel(record)"
                  />
                </template>
                <template slot="dropdown" slot-scope="text, record">
                  <a-dropdown :trigger="['contextmenu']">
                    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> -->
                    <span> {{ text }}</span>
                    <!-- </div> -->
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a-icon
                          type="edit"
                          class="text-warning"
                          @click="sendToForm('update', record)"
                        />
                        <span class="ml-1 text-warning">Edit</span>
                      </a-menu-item>
                      <a-menu-item>
                        <a-icon
                          type="delete"
                          class="text-danger"
                          @click="showDeleteConfirm(record)"
                        />
                        <span class="ml-1 text-danger">Hapus</span>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </template>
                <span slot="tanggal_register" slot-scope="text, record">
                  {{ moment(record.pemohon.tglregister, 'YYYY-MM-DD').format('DD-MM-YYYY') }}
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { Modal } from 'ant-design-vue'

import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  data() {
    return {
      columns: [
        {
          title: 'Actions',
          dataIndex: '#',
          width: 100,
          scopedSlots: {
            customRender: 'operations',
          },
        },
        {
          title: 'Kode Pengajuan',
          dataIndex: 'kode_pengajuan',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kode_pengajuan
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Pokok',
          dataIndex: 'pokok',
          ellipsis: true,
          scopedSlots: {
            customRender: 'dropdown',
          },
        },
        {
          title: 'Bunga',
          dataIndex: 'bunga',
          ellipsis: true,
          scopedSlots: {
            customRender: 'dropdown',
          },
        },
        {
          title: 'Jumlah',
          dataIndex: 'jumlah',
          ellipsis: true,
          scopedSlots: {
            customRender: 'dropdown',
          },
        },
        {
          title: 'Angsuran bulan',
          dataIndex: 'angsuran_bulan',
          ellipsis: true,
          scopedSlots: {
            customRender: 'dropdown',
          },
        },
        {
          title: 'Angsuran pokok',
          dataIndex: 'angsuran_pokok',
          ellipsis: true,
          scopedSlots: {
            customRender: 'dropdown',
          },
        },
        {
          title: 'Angsuran bunga',
          dataIndex: 'angsuran_bunga',
          ellipsis: true,
          scopedSlots: {
            customRender: 'dropdown',
          },
        },
        // {
        //   title: 'SHM atas nama',
        //   dataIndex: 'namapemilik',
        //   scopedSlots: {
        //     customRender: 'dropdown',
        //   },
        // },
      ],
      datatable: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      dropdownVisibility: false,
      loading: false,
      tgl1: moment().startOf('month').format('YYYY-MM-DD'),
      tgl2: moment().endOf('month').format('YYYY-MM-DD'),
      datenow: '',
    }
  },
  created() {
    this.datenow = lou.datenow()
    this.getAllData()
  },
  methods: {
    moment,
    async getExcel(record) {
      console.log('record', record)
      // await lou.getDocumentExcel('analisamikro', record.id)
      const storageRef = await firebase
        .storage()
        .ref('taksasi/template/template_analisamikro.xlsx').getDownloadURL()
      this.loadFile(storageRef, (error, content) => {
        if (error) {
          throw error
        }
        // console.log('content', content)
        const ExcelJS = require('exceljs')
        const wb = new ExcelJS.Workbook()
        // const zip = new PizZip(content)
        wb.xlsx.load(content).then(async (workbook) => {
          console.log(workbook, 'workbook instance')

          const newworksheet = workbook.getWorksheet('DATA NASABAH')
          // console.log('newworksheet', newworksheet)
          // console.log('newworksheet.getCell(A59).value', newworksheet.getCell('A59').value)
          // newworksheet.getCell('A59').value = 'Ini yang tak rubah yeee....'
          // newworksheet.getCell('H7').value = record.datanasabah.PEKERJAAN
          newworksheet.getCell('C4').value = record.datanasabah.nama
          newworksheet.getCell('C5').value = record.datanasabah.alamat
          newworksheet.getCell('C6').value = record.datanasabah.ktp
          newworksheet.getCell('C7').value = record.datanasabah.pekerjaan
          newworksheet.getCell('C8').value = record.datanasabah.alamatkantor
          newworksheet.getCell('C9').value = record.datanasabah.telepon
          newworksheet.getCell('C10').value = record.datanasabah.namaibukandung
          var karakterjujur = 'F'
          // this.gachaKordinat(record.karakter_jujur)
          if (record.karakter_jujur <= 4) {
            karakterjujur = 'F'
          } else if (record.karakter_jujur <= 7) {
            karakterjujur = 'G'
          } else {
            karakterjujur = 'H'
          }
          newworksheet.getCell(karakterjujur + '18').value = record.karakter_jujur
          var karakterslik = 'F'
          if (record.karakter_slik <= 4) {
            karakterslik = 'F'
          } else if (record.karakter_slik <= 7) {
            karakterslik = 'G'
          } else {
            karakterslik = 'H'
          }
          newworksheet.getCell(karakterslik + '19').value = record.karakter_slik
          var karakterkasus = 'F'
          if (record.karakter_kasus <= 4) {
            karakterkasus = 'F'
          } else if (record.karakter_kasus <= 7) {
            karakterkasus = 'G'
          } else {
            karakterkasus = 'H'
          }
          newworksheet.getCell(karakterkasus + '20').value = record.karakter_kasus
          var karaktergayahidup = 'F'
          if (record.karakter_gayahidup <= 4) {
            karaktergayahidup = 'F'
          } else if (record.karakter_gayahidup <= 7) {
            karaktergayahidup = 'G'
          } else {
            karaktergayahidup = 'H'
          }
          newworksheet.getCell(karaktergayahidup + '21').value = record.karakter_gayahidup
          var kemampuanpendidikan = 'N'
          if (record.kemampuan_pendidikan <= 4) {
            kemampuanpendidikan = 'N'
          } else if (record.kemampuan_pendidikan <= 7) {
            kemampuanpendidikan = 'O'
          } else {
            kemampuanpendidikan = 'P'
          }
          newworksheet.getCell(kemampuanpendidikan + '18').value = record.kemampuan_pendidikan
          var kemampuanpengalaman = 'N'
          if (record.kemampuan_pengalaman <= 4) {
            kemampuanpengalaman = 'N'
          } else if (record.kemampuan_pengalaman <= 7) {
            kemampuanpengalaman = 'O'
          } else {
            kemampuanpengalaman = 'P'
          }
          newworksheet.getCell(kemampuanpengalaman + '19').value = record.kemampuan_pengalaman
          var kemampuanrepay = ''
          if (record.kemampuan_repayment <= 4) {
            kemampuanrepay = 'N'
          } else if (record.kemampuan_repayment <= 7) {
            kemampuanrepay = 'O'
          } else {
            kemampuanrepay = 'P'
          }
          newworksheet.getCell(kemampuanrepay + '20').value = record.kemampuan_repayment
          var kemampuansarana = ''
          if (record.kemampuan_sarana <= 4) {
            kemampuansarana = 'N'
          } else if (record.kemampuan_sarana <= 7) {
            kemampuansarana = 'O'
          } else {
            kemampuansarana = 'P'
          }
          newworksheet.getCell(kemampuansarana + '21').value = record.kemampuan_sarana
          newworksheet.getCell('E22').value = record.karakter_score
          newworksheet.getCell('M22').value = record.kemampuan_score
          newworksheet.getCell('E30').value = record.modal_score
          newworksheet.getCell('M30').value = record.jaminan_score
          newworksheet.getCell('P31').value = record.rata_score
          newworksheet.getCell('F33').value = record.penerimaan_penjualan
          newworksheet.getCell('F34').value = record.penerimaan_hargapokok
          newworksheet.getCell('F35').value = record.pendapatan_kotor
          newworksheet.getCell('F38').value = record.biayaops_penjualan
          newworksheet.getCell('F39').value = record.biayaops_umum
          newworksheet.getCell('F40').value = record.biayaops_administrasi
          newworksheet.getCell('F41').value = record.biayaops_jumlah
          newworksheet.getCell('I33').value = record.biaya_keperluan
          newworksheet.getCell('I34').value = record.biaya_pendidikan
          newworksheet.getCell('I35').value = record.biaya_hidup
          newworksheet.getCell('I36').value = record.biaya_jumlah
          newworksheet.getCell('I37').value = record.pendapatan_kotor
          newworksheet.getCell('I39').value = record.angsuran_bank
          newworksheet.getCell('I40').value = record.angsuran_bank
          newworksheet.getCell('I41').value = record.pendapatan_bersih
          newworksheet.getCell('N45').value = record.pasiva_hutangdagang
          newworksheet.getCell('N46').value = record.pasiva_hutangpendek
          newworksheet.getCell('N47').value = record.pasiva_lainnya
          newworksheet.getCell('N48').value = record.pasiva_hutanglancar
          newworksheet.getCell('N49').value = record.pasiva_hutangpanjang
          newworksheet.getCell('N50').value = record.pasiva_jumlahhutang
          newworksheet.getCell('N51').value = record.pasiva_modal
          newworksheet.getCell('N52').value = record.pasiva_jumlah
          const buffer = await workbook.xlsx.writeBuffer()
          var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'testing.xlsx'
          link.click()
        })
      })
    },
    async getAllData() {
      var response = await lou.customUrlGet2('analisamikro?dari=' + this.tgl1 + '&sampai=' + this.tgl2)
      this.data = response.data
    },
    showUpload() {
      this.$refs.file.click()
    },
    sendToForm(action, edata = {}) {
      localStorage.removeItem('dataanalisis')
      localStorage.removeItem('dataeditanalisis')
      if (action === 'create') {
        localStorage.setItem('dataeditanalisis', JSON.stringify({ id: '' }))
      } else {
        localStorage.setItem('dataeditanalisis', JSON.stringify(edata))
      }
      this.$router.push('/analisamikro/form/ultramicro')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.customUrlDelete2('analisamikro/' + deldata.id)
          this.getAllData()
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // this.$refs.datadetail.open(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
            this.dropdownVisibility = true
            // console.log('this.dropdownVisibility', this.dropdownVisibility)
          },
        },
      }
    },
    selectDate(date, dateString) {
      if (date === null) {
        this.tgl1 = moment().startOf('month').format('YYYY-MM-DD')
        this.tgl2 = moment().endOf('month').format('YYYY-MM-DD')
      } else {
        this.tgl1 = date.startOf('month').format('YYYY-MM-DD')
        this.tgl2 = date.endOf('month').format('YYYY-MM-DD')
      }
      this.getAllData()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style></style>
